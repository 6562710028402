/* WHATSAPP ICON */
.whatsappIcon{
    cursor: pointer;
    height: 15vh; 
    display: flex;
    justify-content: space-between;
    position: fixed; 
    bottom: 10px;
    right: 20px;
    align-items: center;
    z-index: 2;
   
}
.principalIcon{
    font-size: 80px;
}

@media (max-width:480px){

    .whatsappIcon{
        height: 8vh;
    }

    .principalIcon{
        font-size: 50px;
    }
}