
.slider{
    width: 100%;
    height: auto;
    margin: auto;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: rgb(247, 247, 247);
  
}
.slider .slide-track{
    
    display: flex;
    animation: scroll 40s linear infinite;
    -webkit-animation: scroll 40s linear infinite;
    width: calc(200px*14);
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.slider .slide{
    width: 200px;
}
.slider .slide img{
    width: 95%;
    height: 80px;
}
@keyframes scroll{
    0%{
        -webkit-transform: translateX(calc(-200px*7));
        transform: translateX(calc(-200px*7));
    }
}

@media (max-width:480px){

    .slider .slide img{
        width: 65%;
        height: 40px;
    }
    .slider .slide-track{
        gap: 25px;
    }

}