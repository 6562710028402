.who{
    display: flex;
    gap: 40px;
    margin-top: 60px;
}
.who-information{
    width: 50%;
}
.who-img{
    width: 50%;
}
.who-information-title{
    border-bottom: 8px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 25px;
}

.who-information-title h1{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.img-technology{
    width: 100%;
    height: 370px;
}

@media (max-width: 480px){
    .who{
        flex-direction: column;
        gap: 0;
    }

    .who-img{
      width: 100%;
    }

    .who-information{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }
}  