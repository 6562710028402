.contact-container{
    /* margin-left: 25px; */
    display: flex;
    gap: 380px;
    margin-top: 60px;
    align-items: center;
    justify-content: center;
    background-color: rgb(247, 247, 247);
    box-shadow: 0px 1px 15px -5px rgba(0, 0, 0, 0.2);
}

.contact-day{
    margin: 0px;
}

.contact-container-days{
    margin-top: 40px;
}

@media (max-width:480px){
    .contact-container{
        display: flex;
        gap: 0;
        justify-content: space-around;
        margin-top: 30px;
        padding-top: 25px;
    }
    .contact-container p{
        font-size: 10px;
    }

    .contact-container-days{
        margin-top: 10px;
    }
}