.c-item{
    width: 40%;
    height: 70vh;
}

.c-img{
    width: 100%;
    height: 100%;
}

.carousel-footer{
    display: flex;
    gap: 100px;
    align-items: center;
    justify-content: center;
    background-color: rgb(247, 247, 247);
    box-shadow: 0px 4px 4px 0px rgba(206, 201, 201, 0.25);
    height: 15vh;

}
.carousel-footer span{
    display: inline-block;
    padding: 0;
}
.carousel-footer-icon{
    font-size: 25px;
  
}

.carousel-item{
    width: 100%;
}

.d-block{
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 90%;
}

@media (max-width: 480px){

    .carousel-item{
        height: 65vh;
    }

    .carousel-footer{       
        gap: 20px;
        height: 12vh;
    }
    .carousel-footer span{
        font-size: 10px;
    }
}  